import { useRouter } from 'next/router';
import { useEffect } from 'react';

import {
  encounteredUnsupportedLocale,
  findAppropriateLocaleForUrl,
  replaceISOLocaleInUrl,
} from 'shared/i18n/helpers';

const useUnsupportedLocaleProtection = (): boolean => {
  // `asPath` contains the original path which caused the 404
  const { asPath, replace } = useRouter();
  const unsupported = encounteredUnsupportedLocale(asPath);
  const rewriteLocale = findAppropriateLocaleForUrl(asPath);

  const redirecting = unsupported && !!rewriteLocale;

  useEffect(() => {
    if (redirecting) {
      const url = replaceISOLocaleInUrl(asPath, rewriteLocale);
      // use a hard replace in order for our server side redirects to be applied
      window.location.replace(url);
    }
  }, [redirecting, rewriteLocale, asPath, replace]);

  return redirecting;
};

export default useUnsupportedLocaleProtection;
